import { useEffect } from "react";
import { PAYMENTINITIATE } from "../../rest/endpoints";
import { loadToken } from "../../utils/localStorage";
import { useAuthHeaders } from "../../utils/getHeaders";
import { useNavigate } from "react-router-dom";

const RazorpayPayment = ({ orderId }) => {
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  useEffect(() => {
    const postserverwithorderid = async () => {
      const userToken = loadToken();
      const response = await fetch(PAYMENTINITIATE, {
        method: "POST",
        headers,
        body: JSON.stringify({ orderId: orderId }),
      });
      const data = await response.json();
      return data;
    };

    const fetchOrderAndPay = async () => {
      const data = await postserverwithorderid();
      initiatePayment(data.data);
    };

    // Function to initiate Razorpay payment
    const initiatePayment = (details) => {
      const options = {
        key: details.razorpayKey,
        name: details.name,
        description: details.description,
        image: details.image,
        order_id: details.razorpayOrderId,
        callback_url: details.callbackUrl,
        customer_id: details.customerId,
        theme: {
          color: details.themeColor,
        },
        // Add handler for modal close
        modal: {
          ondismiss: function () {
            navigate(`/payment/failure?orderId=${orderId}`);
          },
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    };

    fetchOrderAndPay();
  }, []); // Empty dependency array means it runs only once when the component mounts

  return null; // No UI, just triggers payment
};

export default RazorpayPayment;
