import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const OfferSection = ({ offering }) => {
  const itemImages = offering.offeringItemImageList;

  const [positions, setPositions] = useState(
    itemImages.map((_, index) => index) // Initial positions
  );

  const navigate = useNavigate();
  const translations = useSelector((state) => state.language.translations);

  useEffect(() => {
    const interval = setInterval(() => {
      setPositions((prev) => {
        const [first, ...rest] = prev;
        return [...rest, first]; // Rotate the positions array
      });
    }, 5000); // Change position every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const manageOffer = () => {
    localStorage.setItem("particularChadawa", JSON.stringify(offering));

    const mainSection = offering.mainSection;
    const id = offering.id;
    navigate(`/chadawa/${mainSection.name}`, { state: { id } });
  };

  return (
    <div className="flex flex-wrap justify-between items-center mt-4">
      {offering?.eventType === "INPROGRESS_EVENT" && (
        <div className="flex mb-4 sm:mb-2 h-14">
          {itemImages.map((image, index) => (
            <div
              key={index}
              className={`absolute ml-2 transition-all hover:scale-105 hover:translate-y-[-4px]`}
              style={{
                left: `${positions[index] * 20}px`, // Adjust left positioning dynamically
                zIndex: positions[index], // Higher position gets higher z-index
              }}
            >
              <img
                src={image.url}
                alt={image.alt}
                className="w-14 h-14 rounded-xl object-cover shadow-sm hover:shadow-md transition-shadow"
              />
            </div>
          ))}
        </div>
      )}

      <button
        onClick={manageOffer}
        disabled={offering?.eventType !== "INPROGRESS_EVENT"} // Disable the button if eventPassed is true
        className={`h-12 sm:mb-2 w-fit ${
          offering?.eventType !== "INPROGRESS_EVENT"
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-custom-orange hover:bg-orange-600"
        } text-white px-4 sm:px-6 py-2 rounded-[50px] font-poppins sm:text-[1vw] font-semibold`}
      >
        {offering?.eventType === "INPROGRESS_EVENT"
          ? translations.offerChadawa
          : offering?.eventType === "EVENT_END"
          ? translations.eventEnded
          : offering?.eventType === "UPCOMING_EVENT"
          ? translations.eventUpcoming
          : ""}
      </button>
    </div>
  );
};

export default OfferSection;
