import React from "react";

import InformationSection2 from "../Common/InformationSection2";
import ImageSection from "../Common/ImageSection_courosel";

import { useSelector } from "react-redux";

const AboutChadawa = ({ pooja }) => {
  const translations = useSelector((state) => state.language.translations);
  return (
    <div className="bg-inherit sm:px-20 px-10 ">
      <div className="">
        <h1 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon font-poppins font-semibold pt-10 pb-4 text-center sm:text-left">
          {translations.aboutPuja}
        </h1>
        <div className="grid lg:grid-cols-2 gap-4">
          <div>
            <ImageSection imagesa={pooja.aboutSection.imageInfoList} />
            <p className="mt-4 text-gray-700  lg:w-[52vw] font-poppins text-base md:text-lg">
              {pooja.aboutSection.description}
            </p>
          </div>
          <InformationSection2 />
        </div>
      </div>
    </div>
  );
};

export default AboutChadawa;
