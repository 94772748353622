import React from "react";

import DiscountLabel from "../../Chadawa/DiscountLabel";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import ConditionalRenderer from "../../../devsathan_components/ConditionalRenderer";

const ImageSection = ({ offering }) => {
  const mainSection = offering.mainSection;
  const titleImageInfo = mainSection.titleImageInfo;

  // eventpassed start#########################
  const eventPassed = new Date() > new Date(mainSection.eventDateTime);
  console.log(offering);

  return (
    <div className="relative mb-2">
      <div className="max-h-[300px] overflow-hidden rounded-t-[24px]">
        {!!offering.attendeeStatusText && (
          <div className="absolute w-fit h-[32px] bg-[#e59419] text-white text-sm font-medium px-3 pt-1 rounded-br-[24px] z-10 font-poppins flex items-center">
            <TrendingUpIcon className="mr-2" />
            <span className="mr-4">{offering.attendeeStatusText}</span>
          </div>
        )}
        <img
          src={titleImageInfo.url}
          alt="#"
          className="w-full aspect-[3/2] object-cover rounded-br-[24px] rounded-bl-[24px] max-h-[250px] h-full"
          loading="lazy"
        />{" "}
        {!!mainSection.discountText && (
          <DiscountLabel discount={mainSection.discountText} />
        )}
        <ConditionalRenderer componentKey="whatsappbutton">
          <button className="absolute top-2 right-2 bg-green-500 text-white rounded-full p-3 flex items-center justify-center">
            <WhatsAppIcon />
          </button>
        </ConditionalRenderer>
      </div>
      {eventPassed && (
        <div className="z-10 absolute inset-0 bg-opacity-50 backdrop-blur-md flex items-center justify-center">
          <span className="text-white font-bold text-lg sm:text-xl">
            Event Ended
          </span>
        </div>
      )}
    </div>
  );
};

export default ImageSection;
