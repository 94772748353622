import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiRequest";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { OTPSEND, OTPVERIFY } from "../../rest/endpoints";
import { useNavigate } from "react-router-dom";
import { saveToken } from "../../utils/localStorage";
import { trackMetaEvent } from "../../utils/metaPixel";
import { openModal } from "../../utils/openModal";
import { useSelector } from "react-redux";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import { SEND_OTP, COMPLETE_REGISTRATION } from "../../utils/gtmEventConstants";
import { generalloadToken } from "../../utils/localStorage";
import { generalsaveToken } from "../../utils/localStorage";
import { removegeneralToken } from "../../utils/localStorage";

const OTPVerification = ({ setUserDetails, setIsOtpVerified }) => {
  const [phone, setPhone] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [cooldown, setCooldown] = useState(0);
  const navigate = useNavigate();
  const currentLanguage = useSelector(
    (state) => state.language.currentLanguage
  );
  const translations = useSelector((state) => state.language.translations);
  const showModal = useSelector((state) => state.showModal.showModal);

  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldown]);

  const handleSendOtp = async () => {
    if (!/^\d{10}$/.test(phone)) {
      toast("Please enter a valid 10-digit mobile number.");
      return;
    }
    try {
      const result = await apiRequest({
        url: OTPSEND,
        method: "POST",
        data: { phone },
        headers: { "Content-Type": "application/json" },
      });
      pushGTMEvent(SEND_OTP, {
        event_value: "SUCCESS",
      });

      setIsOtpSent(true);
      setCooldown(30);
      toast.success("OTP sent successfully!");
      console.log("OTP Response:", result);
    } catch (error) {
      console.error(
        "Error sending OTP:",
        error.response?.message || error.message || "Network or unknown error"
      );
      pushGTMEvent(SEND_OTP, {
        event_value: "FAILURE",
      });
      toast.error("Failed to send OTP. Please try again later.");
    }
  };

  const handleResendOtp = async () => {
    console.log(phone);

    if (cooldown > 0) return;
    handleSendOtp();
  };

  const handleVerifyOtp = async () => {
    try {
      const result = await apiRequest({
        url: OTPVERIFY,
        method: "POST",
        data: { phone, otp },
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": currentLanguage,
        },
      });

      console.log("OTP Verification Response:", result.data);

      const sessionId = result?.data?.sessionId;
      const userId = result?.data?.userDataResponse?.userId;

      if (
        sessionId &&
        sessionId.trim() !== "" &&
        userId &&
        userId.trim() !== ""
      ) {
        generalsaveToken("userId", userId);
        saveToken(sessionId);
        console.log("Session ID saved:", sessionId);
      } else {
        console.warn("No valid Session ID found. Token not saved.");
        // toast.error("Invalid OTP. Please try again.");
        toast(result?.message);
        return;
      }

      pushGTMEvent(COMPLETE_REGISTRATION, {
        event_value: "complete_registration",
        guest_id: generalloadToken("guestId"),
        user_id: generalloadToken("userId"),
      });

      trackMetaEvent("COMPLETE_REGISTRATION");

      // removing guestId after user is logged in

      removegeneralToken("guestId");

      if (result?.data?.redirectTo === "PROFILE") {
        setUserDetails({ phone });
        setIsOtpVerified(true);
      } else {
        if (!showModal) {
          navigate("/");
        } else {
          openModal({ value: false });
        }
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      // toast.error("OTP verification failed. Please try again.");
    }
  };

  return (
    <div className="bg-gradient-to-br from-custom-beige to-custom-light-beige p-6 rounded-[24px] max-w-[90vw] w-fit shadow-sm transition-transform hover:scale-105">
      <h2 className="text-3xl font-bold mb-1 text-custom-maroon animate-fade-in">
        Login To Devsthan
      </h2>
      <p className="text-md text-[#FF9933]">Continue your devotional journey</p>
      <div className="relative">
        <input
          type="text"
          inputMode="numeric"
          placeholder="Mobile Number"
          className="border p-2 max-w-[70vw] w-fit md:w-[30vw] mb-4 mt-6 rounded-xl focus:ring-2 focus:ring-custom-orange focus:outline-none pl-10"
          value={phone}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setPhone(numericValue);
          }}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "Delete" &&
              e.key !== "Tab"
            ) {
              e.preventDefault();
            }
          }}
        />
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pt-2">
          <LocalPhoneIcon className="text-custom-maroon" />
        </span>
      </div>
      {isOtpSent && (
        <div className="animate-slide-in" style={{ animationDuration: "0.5s" }}>
          <input
            type="text"
            placeholder="Enter OTP"
            className="border p-2 max-w-[70vw] w-fit md:w-[30vw] mb-4 rounded-xl focus:ring-2 focus:ring-custom-orange focus:outline-none pl-10"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <div className="flex items-center mb-4 text-sm">
            <button
              onClick={handleResendOtp}
              disabled={cooldown > 0}
              className={`text-custom-orange hover:text-custom-maroon transition-colors ${
                cooldown > 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Resend OTP
            </button>
            {cooldown > 0 && (
              <span className="ml-2 text-gray-500">
                Wait {cooldown} seconds to resend
              </span>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-end">
        {!isOtpSent ? (
          <button
            onClick={handleSendOtp}
            className="border-2 border-custom-orange text-custom-orange hover:bg-custom-orange hover:text-white px-10 py-2 rounded-[24px] w-fit font-semibold shadow transition-transform hover:scale-105"
          >
            Send OTP
          </button>
        ) : (
          <button
            onClick={handleVerifyOtp}
            className="border-2 border-custom-orange text-custom-orange hover:bg-custom-orange hover:text-white px-10 py-2 rounded-[24px] w-fit font-semibold shadow transition-transform hover:scale-105"
          >
            Verify OTP
          </button>
        )}
      </div>
    </div>
  );
};

export default OTPVerification;
