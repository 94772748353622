import React, { useRef, useEffect, useState } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PujaEventCard from "./pujaeventcard";
import ExploreMoreCard from "../../devsathan_components/ExploreMore/exploremore";
import { useNavigate } from "react-router-dom";
import {SLIDER_PUJA} from "../../utils/gtmEventConstants";
import { pushGTMEvent } from "../../utils/GTMEventComponent";

const PujaEventSlider = ({ events }) => {
  const sliderRef = useRef(null);
  const sliderContainerRef = useRef(null);
  const navigate = useNavigate();
  const [hasScrolled, setHasScrolled] = useState(false);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  const handleExploreMore = () => {
    navigate("/puja-listing");
  };

  useEffect(() => {
    const slider = sliderRef.current;
  
    const handleScroll = () => {
      if (slider && !hasScrolled) {
        const scrollLeft = slider.scrollLeft;
        const cardWidth = slider.firstChild?.offsetWidth || 0;
  
        // If user has scrolled past half of first card
        if (scrollLeft > cardWidth / 2) {
          setHasScrolled(true);
          pushGTMEvent(SLIDER_PUJA);
        }
      }
    };
  
    if (slider) {
      slider.addEventListener("scroll", handleScroll);
    }
  
    return () => {
      if (slider) {
        slider.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasScrolled]);
  
  return (
    <div ref={sliderContainerRef} className="w-full overflow-hidden pt-8 relative">
      {/* Desktop left arrow */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full hidden sm:block"
      >
        <ArrowBackIos className="px-1" />
      </button>

      <div className="mx-auto px-0 md:px-20 relative">
        <div
          ref={sliderRef}
          className="flex gap-7 sm:gap-40 overflow-x-auto pb-4 snap-x w-full scroll-smooth
  [&::-webkit-scrollbar]:w-0 
  [&::-webkit-scrollbar]:h-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-black
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-white
  dark:[&::-webkit-scrollbar-track]:bg-black
  dark:[&::-webkit-scrollbar-thumb]:bg-white"

        >
          {events.map((pujaEvent, index) => (
            <div
              key={pujaEvent.id}
              className="snap-start flex-shrink-0 w-[84.5vw] sm:w-72 relative"
            >
              <PujaEventCard
                pujaEvent={pujaEvent}
                onButtonClick={() =>
                  navigate(
                    `/puja/${pujaEvent?.mainSection?.name.replace(/\s+/g, "_")}`
                  )
                }
              />
              
            </div>
          ))}

          {events.length > 0 && (
            <div className="snap-start flex-shrink-0 w-72 h-full p-2 pt-16 pb-9">
              <ExploreMoreCard onClick={handleExploreMore} />
            </div>
          )}
        </div>
      </div>

      {/* Desktop right arrow */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-[15.5rem] sm:top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white rounded-full"
      >
        
        <ArrowForwardIos className="px-1" />
      </button>
    </div>
  );
};

export default PujaEventSlider;