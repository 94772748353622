import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DateSection from "../Cards/offeringCardComponents/DateSection";
import TitleAndLocation from "../Cards/offeringCardComponents/TitleAndLocation";

import { useSelector } from "react-redux";

const ReviewCartItems = ({ cart, type, parentData }) => {
  const translations = useSelector((state) => state.language.translations);

  const navigate = useNavigate();
  const handleClick = () => {
    if (type === "PUJA") {
      navigate("/puja-listing");
    } else if (type === "OFFERING") {
      navigate("/chadawa");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="w-full min-h-full bg-custom-beige lg:rounded-l-[24px] sm:border-b-2 border-gray-300 lg:border-b-0 relative">
      <div className="w-full lg:border-b-2 lg:border-gray-300 py-2 h-[65px] items-center px-4 hidden md:flex">
        <h1 className="text-xl font-semibold text-gray-700 flex items-center">
          <ArrowBackIcon className="mr-2" onClick={handleClick} />{" "}
          {translations.reviewBooking}
        </h1>
      </div>
      <div className="px-6 md:px-10 lg:px-16 pt-3 pb-10 hidden md:block">
        {/* Show loading if `parentData` is empty */}
        {!parentData || parentData.length === 0 ? (
          <div className="flex justify-center items-center py-10">
            <p className="text-gray-500">Loading...</p>
          </div>
        ) : (
          <>
            <DateSection offering={parentData?.mainSection} />
            <div className="pt-3 flex flex-col justify-center items-center">
              {parentData?.mainSection?.titleImageInfo.url ? (
                <img
                  className="w-full aspect-[7/4] rounded-[24px]"
                  src={parentData?.mainSection?.titleImageInfo.url}
                  alt="Puja"
                />
              ) : (
                <p className="text-gray-500">No image available</p>
              )}
            </div>
            <TitleAndLocation offering={parentData?.mainSection} />
          </>
        )}
      </div>
      <div className="bg-custom-beige bg-opacity-100 w-full px-6 sm:px-8 md:px-10 lg:px-16 md:rounded-[24px] border-gray-600 py-6 pb-2 md:relative">
        <h2 className="text-lg font-bold text-custom-orange mb-4 text-center">
          {translations.bookingSummary}
        </h2>
        <h4 className="text-lg  text-custom-orange mb-4">
          {parentData?.mainSection?.name}
        </h4>

        {cart?.itemList
          ?.filter((item) => item.subType === "PUJA_PACKAGE")
          .concat(
            cart?.itemList.filter((item) => item.subType !== "PUJA_PACKAGE")
          )
          .map((item) => (
            <div
              key={item.itemId}
              className="flex flex-row justify-between w-full border-b border-gray-500 py-1"
            >
              <span className="font-semibold text-gray-700 pt-1">
                {item.name}
              </span>
              <span className="font-medium text-custom-orange">
                ₹ {item.itemAmount * item.quantity}
              </span>
            </div>
          ))}

        <div className="flex justify-between items-center pt-3 pb-3">
          <span className="font-semibold text-gray-700">
            {translations.totalPrice}
          </span>
          <span className="font-semibold text-custom-orange">
            ₹{" "}
            {cart?.itemList?.length > 0
              ? cart.totalAmount
              : "No item available"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewCartItems;
