import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import cart from "../reducers/cart";
import pujaPackage from "../reducers/pujaPackage";
import refreshTrigger from "../reducers/refreshTrigger";
import showModal from "../reducers/showModal";
import language from "../reducers/language";

// Configure persistence for cart and pujaPackage only
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["cart", "pujaPackage", "language"], // Persist only these
};

// Combine all reducers
const rootReducer = combineReducers({
  cart,
  pujaPackage,
  refreshTrigger,
  showModal,
  language, // Add the new reducer (without persistence)
});

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
