import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector } from "react-redux";

const InformationSection2 = () => {
  const translations = useSelector((state) => state.language.translations);

  const information = translations.pujaInformation;

  return (
    <div className="lg:pl-44">
      <div className="grid lg:grid-cols-1 grid-cols-1 sm:grid-cols-2 gap-2">
        {information?.map((info) => (
          <div key={info.id} className="flex flex-col md:flex-col">
            <div className="flex flex-row">
              <div className="text-custom-orange pr-2">{info.icon}</div>
              <h1 className="font-poppins font-semibold text-[1.1rem] text-custom-maroon pb-1">
                {info.title}
              </h1>
            </div>
            <div>
              <p className="font-poppins font-light text-sm text-custom-light-brown pb-2">
                {info.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InformationSection2;
