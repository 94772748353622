
import {generalloadToken} from './localStorage';


// Universal details - sent with every event



const getUniversalDetails = () => ({
    browser: navigator.userAgent,
    is_logged_in: !!localStorage.getItem("userToken"),
    event_time: new Date().toISOString(),
    customer_id: generalloadToken("userId") ? generalloadToken("userId") : generalloadToken("guestId"),
  });
  
  // Generalized function to push events to GTM
  const pushGTMEvent = (eventName, customData = {}) => {
    console.log("GTM event triggered", eventName, customData);
    if (process.env.NODE_ENV !== 'production') {
      console.log("Skipping GTM event in non-production environment", eventName, customData,getUniversalDetails());
      return;
  }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...getUniversalDetails(), // Always include universal details
      ...customData, // Additional custom fields per event
    });
  };
  
  export { pushGTMEvent };
  