import React from "react";
import CartCard from "../Cards/CartCard";

const Cart = ({ chadawaId, items, eventType }) => {
  return (
    <div className="sm:px-20 px-10 py-4">
      {eventType === "EVENT_END" && (
        <div className="bg-red-100 text-red-700 px-4 py-4 sm:py-16 rounded-lg text-lg sm:text-2xl font-semibold shadow-md border border-red-300 text-center mb-16">
          The event has ended!
        </div>
      )}
      {eventType === "INPROGRESS_EVENT" && (
        <div>
          <div>
            <h1 className="flex justify-center font-bold text-3xl md:text-4xl lg:text-4xl text-custom-maroon pb-8">
              Chadawa Items
            </h1>
          </div>
          <div className="flex items-center justify-center h-full">
            <CartCard items={items} chadawaId={chadawaId} />
          </div>
        </div>
      )}

      {eventType === "UPCOMING_EVENT" && (
        <div className="bg-red-100 text-red-700 px-4 py-4 sm:py-16 rounded-lg text-lg sm:text-2xl font-semibold shadow-md border border-red-300 text-center mb-16">
          The event is upcoming. Stay tuned!
        </div>
      )}
    </div>
  );
};

export default Cart;
