import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { TRANSACTIONDETAILS } from "../../rest/endpoints";
import RazorpayPayment from "../Common/RazorpayPayment";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiRequest";
import { useAuthHeaders } from "../../utils/getHeaders";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import {PAYMENT_STATUS} from "../../utils/gtmEventConstants";

const styles = `
@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0.3;
  }
}

.wave-circle {
  animation: wave 1.5s linear infinite;
}

.wave-circle-2 {
  animation: wave 1.5s linear infinite;
  animation-delay: 0.5s;
}

.wave-circle-3 {
  animation: wave 1.5s linear infinite;
  animation-delay: 1s;
}
`;

const FailureIcon = () => (
  <div className="relative w-20 h-20 flex items-center justify-center">
    {/* Add style tag for animations */}
    <style>{styles}</style>

    {/* Smooth wave effect with adjusted spacing */}
    <div className="absolute w-full h-full rounded-full bg-red-100/30 wave-circle"></div>
    <div className="absolute w-full h-full rounded-full bg-red-100/30 wave-circle-2"></div>
    <div className="absolute w-full h-full rounded-full bg-red-100/30 wave-circle-3"></div>
    {/* Inner static glowing effect */}
    <div className="absolute w-20 h-20 rounded-full bg-red-100/50 shadow-md"></div>

    {/* Check Icon Circle */}
    <div className="relative w-16 h-16 rounded-full bg-red-500 flex items-center justify-center shadow-lg">
      <CloseIcon sx={{ color: "white", fontSize: 32 }} />
    </div>
  </div>
);

// Custom Labels Mapping
const customLabels = {
  orderId: "Order ID",
  orderDisplayName: "Order Status",
  totalAmount: "Total Amount",
  paymentId: "Payment ID",
  paymentMethod: "Payment Method",
};


const PaymentFailure = () => {
  const headers = useAuthHeaders();
  const navigate = useNavigate();
  const OrderIdparams = new URLSearchParams(window.location.search);
  const OrderId = OrderIdparams.get("orderId");
  const [transactionDetails, setTransactionDetails] = useState({});
  const [showRazorpay, setShowRazorpay] = useState(false);

  const handlePayment = () => {
    setShowRazorpay(true); // Show Razorpay component when button is clicked
  };

  useEffect(() => {
    const fetchData = async () => {
      const userToken = localStorage.getItem("userToken");
      try {
        const result = await apiRequest({
          url: `${TRANSACTIONDETAILS}${OrderId}`,
          method: "GET",
          headers,
        });

        if (result?.data) {
          pushGTMEvent(PAYMENT_STATUS , {
            event_value: "PAYMENT_FAILURE",
            order_id: result.data.orderId,
            total_amount: result.data.totalAmount,
          }
          );
          setTransactionDetails(result.data);
        } else {
          console.error(
            "Transaction data is missing or in an unexpected format."
          );
          toast.error("Failed to retrieve transaction details.");
        }
      } catch (error) {
        console.error(
          "Error fetching transaction details:",
          error.response?.message || error.message || "Network or unknown error"
        );
        toast.error("Error fetching transaction details. Please try again.");
      }
    };

    fetchData();
  }, [OrderId]);

  const gotohomepage = () => {
    navigate("/");
  };

  

  return (
    <div className="min-h-screen bg-[#FFDAD6] flex items-center justify-center p-4 md:p-8 mt-16">
      <div className="max-w-lg w-full space-y-6">
        {/* Main Card */}
        <div className="bg-white rounded-xl shadow-lg p-2 sm:p-8">
          {/* Success Icon Section */}
          <div className="flex flex-col items-center text-center mb-8">
            <div className="mb-6">
              <FailureIcon />
            </div>

            <h1 className="text-3xl font-bold text-[#3a0b01] mb-2">
              Payment Failed
            </h1>

            <p className="text-[#8F4C38] text-lg">
              {transactionDetails?.paymentDetails?.message}
            </p>
          </div>

          {/* Transaction Details Card */}
          <div className="bg-[#FFDAD6] rounded-lg p-4 mb-8 text-[11px] md:text-[13px]">
            {/* <h2 className="text-[#2B1700] font-semibold mb-4 ">OrderId {transactionDetails.OrderId}</h2> */}
            <h2 className="text-[#2B1700] font-semibold mb-4 text-center text-[16px]">
              Transaction Details
            </h2>
            <div className="space-y-3 ">
            {Object.entries(transactionDetails)
    .flatMap(([key, value]) => {
      if (key === "paymentDetails" && typeof value === "object" && value !== null) {
        // Extract only allowed keys from paymentDetails
        return Object.entries(value)
          .filter(([subKey]) => customLabels[subKey])
          .map(([subKey, subValue]) => [subKey, subValue]);
      }
      return customLabels[key] ? [[key, value]] : []; // Include only customLabels keys
    })
    .map(([key, value], index) => (
      <div key={index} className="grid grid-cols-3 gap-4">
        <span className="text-[#6C5D2F] font-semibold col-span-1">
          {customLabels[key]}
        </span>
        <span className="text-[#2B1700] font-medium col-span-2">
          {value?.toString() || "N/A"}
        </span>
      </div>
    ))}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="grid ">
            <button
              className="bg-[#FF5722] hover:bg-[#3a0b01] text-white py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2"
              onClick={handlePayment}
            >
              Pay Now
            </button>

            {/* Show RazorpayPayment only when button is clicked */}
            {showRazorpay && OrderId && <RazorpayPayment orderId={OrderId} />}
          </div>
        </div>

        {/* Return Button */}
        <button
          onClick={gotohomepage}
          className="w-full bg-white border border-[#F1E3D6] text-[#333333] py-4 px-6 rounded-lg hover:bg-[#F1E3D6] transition-colors duration-200 flex items-center justify-center gap-2"
        >
          <ArrowBackIcon />
          Return to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentFailure;
