import React from "react";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import ConditionalRenderer from "../devsathan_components/ConditionalRenderer";

const Footer = () => {
  const translations = useSelector((state) => state.language.translations);

  return (
    <footer className="bg-[#FFF5EB] pt-12 pb-6 sm:py-12 px-6 md:px-20">
      <div className="max-w-[1440px] mx-auto">
        <div className="flex flex-col md:flex-row md:justify-between gap-8">
          {/* Logo Section */}
          <div className="order-2 md:order-1 md:w-1/3">
            <div className="flex flex-col space-y-4 items-center md:items-start text-center md:text-left">
              <div className="flex items-center space-x-2 w-full">
                <div className="w-24">
                  <img src={logo} alt="Devsthan Logo" className="w-full" />
                </div>
                <span className="text-gray-800 font-semibold text-lg flex-grow text-left">
                  DEVOTION SPHERE PRIVATE LIMITED
                </span>
              </div>
              <div className="flex space-x-4 justify-center md:justify-start w-full">
                <Link to="#" className="text-gray-700 hover:text-gray-900">
                  <Facebook size={24} />
                </Link>
                <Link to="#" className="text-gray-700 hover:text-gray-900">
                  <Instagram size={24} />
                </Link>
                <Link to="#" className="text-gray-700 hover:text-gray-900">
                  <Twitter size={24} />
                </Link>
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className="order-1 md:order-2 md:w-1/2">
            <div className="rounded-lg">
              <div className="text-left">
                <div>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4">
                    {translations.footerHeading}
                  </h2>
                  <p className="text-gray-600 mb-4">
                    {translations.footerSubHeading}
                  </p>
                  <p className="text-gray-600 mb-2">
                    <strong>Email:</strong> support@devsthan.in
                  </p>
                  <p className="text-gray-600 mb-2">
                    <strong>Whatsapp:</strong> +91 6361640799
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
