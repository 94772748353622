import React, { useEffect } from "react";

import { useAuthHeaders } from "../utils/getHeaders";
import { useNavigate } from "react-router-dom";
import { GETUSERDATA } from "../rest/endpoints";
import { apiRequest } from "../utils/apiRequest";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const headers = useAuthHeaders();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const translations = useSelector((state) => state.language.translations);

  useEffect(() => {
    const fetchUserData = async () => {
      const result = await apiRequest({
        url: GETUSERDATA,
        method: "GET",
        headers,
      });

      // Update state with user data
      setUserData(result?.data);
      setIsLoading(false);
    };
    fetchUserData();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="min-h-fit pt-16 sm:pt-0 bg-light-pink">
      {isLoading ? (
        <div className=" x flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
          <p className="text-center text-lg font-semibold text-custom-maroon">
            Loading...
          </p>
          <div className="animate-ping rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
        </div>
      ) : !userData ? (
        <div>No data available</div>
      ) : (
        <div className="w-full sm:min-h-screen sm:w-full sm:flex sm:items-center sm:justify-center">
          <div className="bg-custom-beige rounded-[24px] shadow-lg overflow-hidden min-w-fit sm:min-w-[600px]">
            {/* Header Section */}
            <div className="p-6 text-center">
              <div className="flex justify-center mb-4">
                <div className="h-24 w-24 rounded-full bg-blue-100 flex items-center justify-center">
                  {/* Simple user icon using HTML */}
                  <span className="text-4xl text-blue-600">👤</span>
                </div>
              </div>
              <h1 className="text-2xl font-bold text-gray-900">
                {translations.profileInformaton}
              </h1>
            </div>

            {/* Content Section */}
            <div className="p-6 space-y-6">
              {/* Name Field */}
              <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
                <div className="w-5 h-5 text-gray-500 flex items-center justify-center">
                  <span className="text-lg">
                    <PersonIcon />
                  </span>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Name</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {userData.name}
                  </p>
                </div>
              </div>

              {/* Phone Field */}
              <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
                <div className="w-5 h-5 text-gray-500 flex items-center justify-center">
                  <span className="text-lg">
                    <LocalPhoneIcon />
                  </span>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Phone</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {userData.phone}
                  </p>
                </div>
              </div>

              {/* Logout Button */}
              <button
                onClick={handleLogout}
                className="w-full border-2 border-custom-orange hover:bg-custom-orange py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2 hover:text-slate-50 text-custom-orange"
              >
                <span className="">
                  <LogoutIcon />
                </span>
                <span className="font-semibold ">{translations.logout}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
