import React, { useState, useEffect } from 'react';
import { PlayCircle } from "@mui/icons-material";

const UniversalVideoPlayer = ({ videoUrl, orderDetails, translations }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isYouTube, setIsYouTube] = useState(false);
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    // Check if the URL is a YouTube URL
    const url = videoUrl || orderDetails?.videoUrl;
    if (url) {
      // Match various YouTube URL formats
      const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
      const match = url.match(youtubeRegex);
      
      if (match && match[1]) {
        setIsYouTube(true);
        setVideoId(match[1]);
      } else {
        setIsYouTube(false);
      }
    }
  }, [videoUrl, orderDetails?.videoUrl]);

  const handlePlayClick = () => {
    setIsPlaying(true);
    
    // If it's a direct video, find and play it
    if (!isYouTube) {
      const videoElement = document.querySelector('video');
      if (videoElement) {
        videoElement.play().catch(error => {
          console.error("Error playing video:", error);
        });
      }
    }
  };

  // Render video player based on completion status
  if (orderDetails?.orderStatus !== "COMPLETED") {
    return (
      <div className="relative w-full h-0 pb-[56.25%] rounded-2xl overflow-hidden shadow-xl">
        <img
          src={orderDetails?.image}
          alt={orderDetails?.name}
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/40 text-white p-6 text-center">
          <div className="bg-white/10 backdrop-blur-sm p-4 rounded-lg border border-white/20">
            <h3 className="text-xl font-semibold mb-2">
              {translations?.pujaInProcess || "Your Puja is in process"}
            </h3>
            <p>{translations?.oncePujaCompleted || "Once the puja is completed, the video will be available here."}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-0 pb-[56.25%] rounded-2xl overflow-hidden shadow-xl">
      {!isPlaying && (
        <button
          className="absolute inset-0 flex items-center justify-center bg-black/50 z-10 group"
          onClick={handlePlayClick}
        >
          <PlayCircle className="w-16 h-16 text-white opacity-80 group-hover:opacity-100 group-hover:scale-110 transition-all duration-300" />
        </button>
      )}
      
      {isYouTube ? (
        <iframe
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          src={`https://www.youtube.com/embed/${videoId}${isPlaying ? '?autoplay=1' : ''}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <video
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          controls={isPlaying}
          playsInline
        >
          <source src={videoUrl || orderDetails?.videoUrl} type="video/mp4" />
          {translations?.noBrowserSupport || "Your browser does not support the video tag."}
        </video>
      )}
    </div>
  );
};

export default UniversalVideoPlayer;