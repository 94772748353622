import React from "react";
import { useSelector } from "react-redux";

const ServiceCard = ({ title, description, className = "" }) => (
  <div className={`bg-white rounded-xl p-6 ${className}`}>
    <div className="flex gap-4">
      <div>
        <h3 className="text-[#3A0B01] font-bold mb-1">{title}</h3>
        <p className="text-[#8F4C38] text-sm leading-tight">{description}</p>
      </div>
    </div>
  </div>
);

const DivineConnection = () => {
  const translations = useSelector((state) => state.language.translations);

  const importantDetails = translations.importantDetails;

  return (
    <div className="bg-[#F8BB71] p-8 md:p-12">
      <div className="flex flex-col md:flex-row mx-auto">
        <div className="mb-6 md:w-1/2">
          <h1 className="text-[29px] md:text-4xl lg:text-4xl text-custom-maroon font-bold mb-4 pr-4">
            {translations.heading3}
          </h1>
          {/*<p className="text-md md:text-lg lg:text-base text-custom-brown max-w-xl">*/}
          {/*  Vedic Rituals by trained priests: Rituals performed by highly*/}
          {/*  trained vedic priests*/}
          {/*</p>*/}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 max-w-3xl md:w-1/8">
          {importantDetails?.map((detail) => (
            <ServiceCard
              key={detail.id}
              title={detail.title}
              description={detail.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DivineConnection;
