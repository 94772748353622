import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async function to load language dynamically
export const loadLanguage = createAsyncThunk(
  "language/loadLanguage",
  async (language) => {
    try {
      const langModule = await import(`../../languages/${language}.js`);
      return langModule.default;
    } catch (error) {
      console.error("Error loading language file:", error);
      return {};
    }
  }
);

const languageSlice = createSlice({
  name: "language",
  initialState: {
    currentLanguage: "english",
    translations: {},
  },
  reducers: {
    setLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLanguage.fulfilled, (state, action) => {
      state.translations = action.payload;
    });
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
