import React, { useState } from "react";

import { PUTUSER } from "../../rest/endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../utils/apiRequest";
import { useAuthHeaders } from "../../utils/getHeaders";
import PersonIcon from "@mui/icons-material/Person";
import { openModal } from "../../utils/openModal";
import { useSelector } from "react-redux";

const SignUp = () => {
  const [name, setName] = useState("");
  const showModal = useSelector((state) => state.showModal.showModal);
  const navigate = useNavigate();
  const headers = useAuthHeaders();

  const handleSubmit = async () => {
    if (!name) {
      alert("Name is mandatory!");
      return;
    }

    try {
      const result = await apiRequest({
        url: PUTUSER,
        method: "POST",
        data: { name },
        headers,
      });
      if (!showModal) {
        navigate("/");
      } else {
        openModal({ value: false });
      }

      console.log("User updated successfully:", result);
    } catch (error) {
      console.error(
        "Error updating user:",
        error.response?.message || error.message || "Network or unknown error"
      );
      toast.error("Failed to update user. Please try again.");
    }
  };

  // const isValidEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validates the email structure
  //   return emailRegex.test(email);

  //   console.log();
  // };

  return (
    <div className="bg-gradient-to-br from-custom-beige to-custom-light-beige p-6 rounded-[24px] max-w-[90vw] w-fit shadow-sm transition-transform hover:scale-105">
      <div className="flex flex-col w-fit md:w-[35vw] animate-fade-in">
        <h2 className="text-3xl font-bold mb-4 text-custom-maroon">
          Complete Your Profile
        </h2>

        {/* Name Input */}
        <div
          className="relative mb-4 animate-slide-in"
          style={{ animationDelay: "0.1s" }}
        >
          <input
            type="text"
            placeholder="Name"
            className="border p-2 rounded-xl mt-8 max-w-[70vw] w-full pl-10 focus:ring-2 focus:ring-custom-orange focus:outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pt-7">
            <PersonIcon className="text-custom-maroon" />
          </span>
        </div>

        {/* <div
          className="relative mb-4 animate-slide-in"
          style={{ animationDelay: "0.2s" }}
        >
          <input
            type="email"
            placeholder="Email (Optional)"
            className={`border p-2 rounded-xl w-full pl-10 focus:ring-2 ${
              isValidEmail(email)
                ? "focus:ring-custom-orange"
                : "focus:ring-red-500"
            } focus:outline-none`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => {
              if (email && !isValidEmail(email)) {
                alert("Please enter a valid email address.");
              }
            }}
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <EmailIcon className="text-custom-maroon" />
          </span>
        </div>

        <div
          className="relative mb-4 animate-slide-in"
          style={{ animationDelay: "0.3s" }}
        >
          <input
            type="text"
            placeholder="Gotra (Optional)"
            className="border p-2 rounded-xl w-full pl-10 focus:ring-2 focus:ring-custom-orange focus:outline-none"
            value={gotra}
            onChange={(e) => setGotra(e.target.value)}
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <TempleHinduIcon className="text-custom-maroon" />
          </span>
        </div> */}

        {/* Register Button */}
        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            className="border-2 border-custom-orange text-custom-orange hover:bg-custom-orange hover:text-white px-10 py-2 rounded-[24px] w-fit font-semibold shadow transition-transform hover:scale-110 animate-fade-in"
            style={{ animationDelay: "0.4s" }}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
