import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

const FAQSection = ({ faqs }) => {
  const translations = useSelector((state) => state.language.translations);

  return (
    <div className="py-2 sm:py-8 px-4">
      <div className="max-w-screen-lg mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon font-bold mb-3">
            {translations.heading7}
          </h2>
          <p className="text-md md:text-lg lg:text-base text-custom-brown">
            {translations.subHeading7}
          </p>
        </div>

        <div className="space-y-4  ">
          {faqs.map((faq) => (
            <Accordion
              key={faq.id}
              className="overflow-hidden p-2"
              style={{
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                borderRadius: "12px",
                backgroundColor: "#FDF4E8",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${faq.id}-content`}
                id={`panel${faq.id}-header`}
                className="rounded-3xl px-6 py-3"
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  color: "#3A0B01",
                }}
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails
                className="px-6 py-4"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#2B1700",
                }}
              >
                <div>{faq.answer.text}</div>
                {faq.answer.bullets.length > 0 && (
                  <ul className="mt-2">
                    {faq.answer.bullets.map((bullet, index) => (
                      <li key={index} className="list-disc ml-4">
                        {bullet}
                      </li>
                    ))}
                  </ul>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
