import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../utils/apiRequest";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useScroll } from "../devsathan_components/ScrollContext";
import ParticularChadawaHeader from "../components/ParticularChadawa/Header";
import BenefitsOfChadawa from "../components/ParticularChadawa/BenefitsOfChadawa";
import SubscriptionForm from "../components/Common/SubscriptionForm";
import Testimonials from "../components/Common/Testimonials";
import Countdown from "../components/Common/Countdown";
import Details from "../components/ParticularChadawa/Details";
import AboutChadawa from "../components/ParticularChadawa/AboutChadawa";
import Cart from "../components/ParticularChadawa/Cart";
import FAQSection from "../components/Common/FAQ";
import { PARTICULARCHADAWA, TESTIMONIAL } from "../rest/endpoints";
import { pushGTMEvent } from "../utils/GTMEventComponent";
import { BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE } from "../utils/gtmEventConstants";
import ConditionalRenderer from "../devsathan_components/ConditionalRenderer";
import { useAuthHeaders } from "../utils/getHeaders";

const ParticularChadawa = () => {
  const { id } = useParams();
  const [scrolled, setScrolled] = useState(false);
  const headers = useAuthHeaders();
  const navigate = useNavigate();
  const translations = useSelector((state) => state.language.translations);
  const [testimonials, setTestimonials] = useState([]);
  const [particularChadawa, setParticularChadawa] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const buttonRef = useRef(null);

  const { waysToParticipateRef } = useScroll();
  const cartRef = useRef(null);

  const { scrollToWaysToParticipate } = useScroll();
  const [isFixed, setIsFixed] = useState(true);
  const stickyRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      },
      {
        root: null, // Observe within the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when at least 10% of the cart is visible
      }
    );

    if (cartRef.current) {
      observer.observe(cartRef.current);
    }

    return () => {
      if (cartRef.current) {
        observer.unobserve(cartRef.current);
      }
    };
  }, [cartRef.current]); // Ensure it re-observes when ref changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiRequest({
          url: `${PARTICULARCHADAWA}${id}`,
          method: "GET",
          headers,
        });

        if (result?.data) {
          setParticularChadawa(result.data); // Set state with the fetched data
        } else {
          console.error("Chadawa data is missing or empty.");
          toast.error("Chadawa data is unavailable.");
        }
      } catch (error) {
        // Handle error gracefully and notify the user
        console.error(
          "Error fetching Chadawa data:",
          error.message || "Network or unknown error"
        );
        toast.error("Failed to fetch Chadawa data. Please try again later.");
      } finally {
        // Ensure loading state is updated regardless of the outcome
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const result = await apiRequest({
        url: TESTIMONIAL,
        method: "GET",
        headers,
      });

      if (result?.data?.reviews && Array.isArray(result.data.reviews)) {
        setTestimonials(result.data.reviews); // Set state with the fetched testimonial data
      } else {
        console.error(
          "Testimonial data is missing or not in the expected format."
        );
        toast.error("Testimonial data is unavailable.");
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const result = await apiRequest({
        url: TESTIMONIAL,
        method: "GET",
        headers,
      });

      if (Array.isArray(result?.data?.reviews)) {
        setTestimonials(result.data.reviews); // Set state with the fetched testimonial data
      } else {
        console.error(
          "Testimonial data is missing or not in the expected format."
        );
        toast.error("Testimonial data is unavailable.");
      }
    };

    fetchTestimonials();
  }, []);

  console.log(particularChadawa);

  return (
    <div className="min-h-screen bg-[#ffece5]">
      {isLoading ? (
        <div className=" x flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
          <p className="text-center text-lg font-semibold text-custom-maroon">
            Loading...
          </p>
          <div className="animate-ping rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
        </div>
      ) : !particularChadawa ? (
        <div>No data available</div>
      ) : (
        <>
          <ParticularChadawaHeader
            particularChadawa={particularChadawa.mainSection}
          />
          <Details particularChadawa={particularChadawa.mainSection} />
          <Countdown props={particularChadawa} type={"chadawa"} />
          <div
            ref={stickyRef}
            className={`${
              isFixed ? "fixed bottom-0 left-0 right-0" : "sticky top-14"
            } z-30 bg-[#FEEFDC] block sm:hidden px-10 pb-4 pt-3 w-full transition-all duration-300 ease-in-out`}
          >
            <div className="flex flex-row w-full md:w-auto md:mt-0 items-center justify-center sm:pl-6">
              {/* <button className="hidden border-2 border-orange-500 text-orange-500 rounded-full font-semibold hover:bg-orange-50 px-4 py-2">
                Share
              </button> */}
              <button
                ref={buttonRef}
                onClick={() => {
                  if (!scrolled) {
                    scrollToWaysToParticipate();
                    pushGTMEvent(BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE, {
                      event_value: particularChadawa?.mainSection?.name,
                    });
                    setScrolled(true);
                  } else {
                    navigate("/cart");
                  }
                }}
                className="bg-orange-500 text-white px-6 py-2 rounded-[12px] font-semibold hover:bg-orange-600 text-[3.5vw]"
              >
                {scrolled
                  ? translations.bookChadawa
                  : translations.offerChadawa}
              </button>
            </div>
          </div>
          <div ref={waysToParticipateRef} className="bg-[#FEEFDC]">
            <div ref={cartRef}>
              <Cart
                chadawaId={particularChadawa?.id}
                items={particularChadawa?.offeringItemList}
                eventType={particularChadawa?.eventType}
              />
            </div>
          </div>
          <AboutChadawa particularChadawa={particularChadawa} />

          <BenefitsOfChadawa
            particularChadawa={particularChadawa?.benefitsSection}
          />
          <ConditionalRenderer componentKey="SubscribeLandingpage">
            <SubscriptionForm />
          </ConditionalRenderer>
          <Testimonials testimonials={testimonials} />
          <div className="pb-10">
            <FAQSection faqs={particularChadawa?.faqSection} />
          </div>
        </>
      )}
    </div>
  );
};

export default ParticularChadawa;
