import React from "react";

import Filters from "./Filters";
import ConditionalRenderer from "../../devsathan_components/ConditionalRenderer";

import { useSelector } from "react-redux";

function ChadawaHeader() {
  const translations = useSelector((state) => state.language.translations);
  return (
    <div>
      <div className="px-8 sm:px-16">
        <div className="text-sm pt-3 pb-4 lg:px-6 text-gray-600 mt-16 font-poppins font-bold">
          {/* <Link to="/" className="pl-1 text-custom-brown">
            <span>Home</span>
          </Link>
          <span className="mx-2 font-poppins font-semibold">/</span>
          <span className="font-poppins font-semibold text-custom-orange">
            Chadawa
          </span> */}
        </div>
        <h1 className="text-3xl md:text-4xl lg:text-4xl text-custom-maroon lg:px-3 font-poppins font-bold whitespace-nowrap">
          {translations.heading8}
        </h1>
      </div>
      <ConditionalRenderer componentKey="filter">
        <Filters />
      </ConditionalRenderer>
    </div>
  );
}

export default ChadawaHeader;
