import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementItemCount,
  decrementItemCount,
} from "../../redux/reducers/cart";
import { CART } from "../../rest/endpoints";
import { apiRequest } from "../../utils/apiRequest";
import { useAuthHeaders } from "../../utils/getHeaders";
import { toast } from "react-toastify";

const CartCard = ({ items, chadawaId }) => {
  const headers = useAuthHeaders();
  const [alreadyInCart, setAlreadyInCart] = useState({});

  const token = localStorage.getItem("userToken");

  const isLoggedIn = () => {
    return !!token;
  };

  useEffect(() => {
    if (isLoggedIn()) {
      const getcartItems = async () => {
        try {
          const result = await apiRequest({
            url: CART,
            method: "GET",
            headers,
          });
          if (result?.data?.type === "PUJA") {
            console.log("No items in chadawa cart");
          } else if (result?.data?.type === "OFFERING") {
            setAlreadyInCart(result.data);
            if (alreadyInCart?.itemList?.length > 0) {
              alreadyInCart.itemList.forEach((item) => {
                dispatch(
                  incrementItemCount({
                    id: item.itemId,
                    amount: item.itemAmount,
                    itemName: item.name,
                    imageInfo: item.imageInfo,
                    chadawaId: alreadyInCart.parentId,
                  })
                );
              });
            }
          }
        } catch (error) {
          console.error(
            "Error fetching data:",
            error.message || "Unknown error"
          );
          toast.error(error.message || "Failed to fetch cart data.");
        }
      };

      getcartItems();
    }
  }, [token]);

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const handleIncrement = async (id, amount, itemName, imageInfo) => {
    try {
      const result = await apiRequest({
        url: CART,
        method: "POST",
        data: {
          type: "OFFERING",
          parentId: chadawaId,
          subType: "OFFERING_ITEM",
          itemId: id,
          quantity: getItemCount(id) + 1,
        },
        headers,
      });

      if (!result.showModal) {
        dispatch(
          incrementItemCount({ id, amount, itemName, imageInfo, chadawaId })
        );
      }
    } catch (error) {
      console.error("Network or unknown error:", error.message);
      toast.error("Failed to update cart. Please try again.");
    }
  };

  const handleDecrement = async (id, amount, itemName, imageInfo) => {
    try {
      const result = await apiRequest({
        url: CART,
        method: "POST",
        data: {
          type: "OFFERING",
          parentId: chadawaId,
          subType: "OFFERING_ITEM",
          itemId: id,
          quantity: Math.max(0, getItemCount(id) - 1), // Prevents negative quantity
        },
        headers,
      });

      if (!result.showModal) {
        dispatch(
          decrementItemCount({ id, amount, itemName, imageInfo, chadawaId })
        );
      }
    } catch (error) {
      console.error("Network or unknown error:", error.message);
      toast.error("Failed to update cart. Please try again.");
    }
  };

  const getItemCount = (id) => {
    const item = cartItems.find(
      (item) => item.id === id && item.chadawaId === chadawaId
    );
    return item ? item.count : 0;
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
        {items.map((item) => {
          const count = getItemCount(item.id);
          const imageInfo = item.imageInfo || {};

          return (
            <div
              key={item.id}
              className="bg-custom-beige rounded-[24px] w-full shadow-[0px_0px_10px_rgba(0,0,0,0.1)]"
            >
              {/* Small screen (mobile) view - Horizontal list item in single line */}
              <div className="sm:hidden p-2">
                <div className="flex items-center gap-2">
                  {/* Image */}
                  <div className="w-12 h-12 rounded-xl overflow-hidden flex-shrink-0">
                    <img
                      src={imageInfo.url}
                      alt={item.name}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  {/* Name and description */}
                  <div className="flex-grow min-w-0">
                    <h2 className="text-sm font-bold text-custom-maroon line-clamp-2">
                      {item.name}
                    </h2>
                    <p className="text-xs line-clamp-3">{item.description}</p>
                  </div>

                  {/* Quantity Controls */}

                  {/* Price */}
                  <div className="flex-shrink-0 text-center">
                    <p className="text-sm font-bold text-custom-maroon whitespace-nowrap pb-2">
                      ₹{item.amount}
                    </p>
                    <div className="flex-shrink-0 flex items-center mr-2">
                      {count === 0 ? (
                        <button
                          className="py-1 px-4 text-xs text-custom-orange font-semibold border-2 border-custom-orange rounded-full hover:bg-custom-orange hover:text-white transition"
                          onClick={() =>
                            handleIncrement(
                              item.id,
                              item.amount,
                              item.name,
                              imageInfo
                            )
                          }
                        >
                          + Add
                        </button>
                      ) : (
                        <div className="flex items-center border-2 border-custom-orange rounded-full">
                          <button
                            className="text-custom-orange font-semibold hover:text-white hover:bg-custom-orange w-6 h-6 rounded-full transition-all flex items-center justify-center"
                            onClick={() =>
                              handleDecrement(
                                item.id,
                                item.amount,
                                item.name,
                                imageInfo
                              )
                            }
                          >
                            -
                          </button>
                          <span className="px-1 text-sm font-semibold">
                            {count}
                          </span>
                          <button
                            className="text-custom-orange font-semibold hover:text-white hover:bg-custom-orange w-6 h-6 rounded-full transition-all flex items-center justify-center"
                            onClick={() =>
                              handleIncrement(
                                item.id,
                                item.amount,
                                item.name,
                                imageInfo
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Medium and larger screens - Current grid card */}
              <div className="hidden sm:block">
                <div className="rounded-[24px] overflow-hidden">
                  <img
                    src={imageInfo.url}
                    alt={item.name}
                    className="w-full aspect-[5/4] object-cover"
                  />
                </div>
                <div className="px-4 pb-4">
                  <div className="mt-4">
                    <h2
                      className="text-lg font-bold text-custom-maroon line-clamp-2"
                      style={{ minHeight: "3.4rem" }} // Adjust based on your line-height
                    >
                      {item.name}
                    </h2>

                    <p className="text-sm text-custom-gray line-clamp-3">
                      {item.description}
                    </p>

                    <div className="flex items-center mt-4">
                      <p className="text-2xl font-bold text-custom-maroon mr-2">
                        ₹{item.amount}
                      </p>
                    </div>
                    {count === 0 ? (
                      <button
                        className="w-full py-2 mt-4 text-custom-orange font-semibold border-2 border-custom-orange rounded-full hover:bg-custom-orange hover:text-white transition"
                        onClick={() =>
                          handleIncrement(
                            item.id,
                            item.amount,
                            item.name,
                            imageInfo
                          )
                        }
                      >
                        + Add
                      </button>
                    ) : (
                      <div className="flex items-center justify-between mt-4 border-2 border-custom-orange rounded-full py-2">
                        <div className="pl-2">
                          <button
                            className="text-custom-orange font-semibold hover:text-white hover:bg-custom-orange w-7 h-7 rounded-full transition-all flex items-center justify-center"
                            onClick={() =>
                              handleDecrement(
                                item.id,
                                item.amount,
                                item.name,
                                imageInfo
                              )
                            }
                          >
                            -
                          </button>
                        </div>
                        <span className="font-semibold">{count}</span>
                        <div className="pr-2">
                          <button
                            className="text-custom-orange font-semibold hover:text-white hover:bg-custom-orange w-7 h-7 rounded-full transition-all flex items-center justify-center"
                            onClick={() =>
                              handleIncrement(
                                item.id,
                                item.amount,
                                item.name,
                                imageInfo
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CartCard;
