import React from "react";
import { useState } from "react";
import TimeLeft from "../ParticularChadawa/countdownComponents/TimeLeft";

import { useRef } from "react";
import { useScroll } from "../../devsathan_components/ScrollContext";
import { useNavigate } from "react-router-dom";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import {
  BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE,
  BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE,
} from "../../utils/gtmEventConstants";

import { useSelector } from "react-redux";

const Countdown = ({ props, type }) => {
  //imported whole api response data as props
  // let seatsLeft = props.totalSeats - props.bookedSeats;

  const mainSection = props.mainSection;
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const translations = useSelector((state) => state.language.translations);
  const buttonRef = useRef(null);
  // return (
  //   <div className="hidden md:block lg:block">
  //     <div className="bg-[#FEEFDC] sticky top-20 z-30 py-4 sm:px-6 px-10 md:px-20 flex flex-col md:flex-row justify-between items-center rounded-lg mb-20">
  //       {/* Left Section - Hidden on Mobile */}
  //       <div className="hidden md:flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8 w-full md:w-auto">
  //         <div className="text-center md:text-left">
  //           <span className="text-gray-600 font-poppins font-semibold">
  //             Closes in
  //           </span>
  //         </div>

  //         <div className="flex items-baseline space-x-2 justify-center md:justify-start">
  //           <TimeLeft
  //             bookingClosingTime={mainSection.eventBookingClosesDateTime}
  //           />
  //         </div>

  //         {props.bookedSeats > 0 && (
  //           <div className="flex items-center space-x-2 justify-center md:justify-start pb-4">
  //             <span className="text-lg text-black">
  //               <SeatsProgress
  //                 totalSeats={props.totalSeats}
  //                 seatsLeft={seatsLeft}
  //               />
  //             </span>
  //             <div>
  //               <span className="text-black font-bold text-lg font-poppins">
  //                 {seatsLeft}
  //               </span>
  //               <span className="text-xs text-custom-gray pl-2">Seats</span>
  //               <p className="text-gray-500 text-xs font-poppins">Remaining</p>
  //             </div>
  //           </div>
  //         )}

  //         {/* <div className="flex items-center space-x-2 justify-center md:justify-start">
  //         <span className="text-lg text-black">
  //           <GoogleIcon />
  //         </span>
  //         <div>
  //           <span className="text-black font-bold text-lg font-poppins">
  //             {props.averageRatings}
  //           </span>
  //           <span className="text-gray-500 text-sm font-poppins pl-2">
  //             {props.totalReviews}
  //           </span>
  //           <p className="text-gray-500 text-xs font-poppins">Average Rating</p>
  //         </div>
  //       </div> */}
  //       </div>

  //       {/* Right Section - Side-by-Side on Mobile */}
  //       <div className="flex flex-row space-x-4 w-full md:w-auto mt-4 md:mt-0 items-center justify-center md:ml-4">
  //         <button className="border-2 border-orange-500 text-orange-500 px-4 py-2 rounded-full font-semibold hover:bg-orange-50 flex-grow md:flex-grow-0">
  //           Share
  //         </button>
  //         <button className="bg-orange-500 text-white px-6 py-2 rounded-[12px] font-semibold hover:bg-orange-600 flex-grow md:flex-grow-0">
  //           Participate Now
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );

  const { scrollToWaysToParticipate } = useScroll();
  return (
    <div className="sm:sticky sm:top-14 sm:flex sm:flex-row flex-col bg-[#FEEFDC] py-4 px-2 sm:px-20 justify-between items-center rounded-lg z-10">
      {/* Left Section */}
      <div className="flex flex-row items-start justify-center w-full md:w-auto">
        <div className="flex flex-row items-center sm:pr-4 md:pr-8 lg:pr-12">
          <div className="text-center md:text-left">
            <span className="text-gray-600 font-poppins font-semibold pr-2 text-sm sm:pl-0">
              {translations.closesIn}
            </span>
          </div>
          <div className="flex items-baseline space-x-2 justify-center md:justify-start">
            <TimeLeft
              bookingClosingTime={mainSection.eventBookingClosesDateTime}
            />
          </div>
        </div>

        {/* <div className="flex items-center space-x-2 justify-center md:justify-start pr-2">
          <span className="text-lg text-black pl-5 sm:pr-2">
            <SeatsProgress
              totalSeats={props.totalSeats}
              seatsLeft={seatsLeft}
            />
          </span>
          <div>
            <span className="text-black font-bold text-lg font-poppins">
              {seatsLeft}
            </span>
            <span className="text-xs text-custom-gray pl-2">Seats</span>
            <p className="text-gray-500 text-xs font-poppins">Remaining</p>
          </div>
        </div> */}
      </div>

      {/* Right Section */}
      <div className="hidden sm:flex sm:flex-row w-full md:w-auto mt-4 md:mt-0 items-center justify-between sm:pl-6">
        <button className="border-2 border-orange-500 text-orange-500 px-4 mr-6 py-2 rounded-full font-semibold hover:bg-orange-50 flex-grow md:flex-grow-0">
          {translations.shareButton}
        </button>
        <button
          ref={buttonRef}
          onClick={() => {
            if (type === "chadawa") {
              if (!scrolled) {
                scrollToWaysToParticipate();
                pushGTMEvent(BOOK_CHADAWA_PARTICIPATE_NOW_PARTICULAR_PAGE, {
                  event_value: props?.mainSection?.name,
                });
                setScrolled(true);
              } else {
                navigate("/cart");
              }
            } else {
              scrollToWaysToParticipate();
              pushGTMEvent(BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE, {
                event_value: props?.mainSection?.name,
              });
            }
          }}
          className="bg-orange-500 text-white px-4 py-2 rounded-[12px] font-semibold hover:bg-orange-600 whitespace-nowrap text-md"
        >
          {scrolled
            ? type === "chadawa"
              ? translations.bookChadawa
              : translations.participateNow
            : type === "chadawa"
            ? translations.offerChadawa
            : translations.participateNow}
        </button>
      </div>
    </div>
  );
};

export default Countdown;
