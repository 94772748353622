import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CART } from "../../rest/endpoints";
import { useAuthHeaders } from "../../utils/getHeaders";
import { emptyCart } from "../../redux/reducers/cart";
import { apiRequest } from "../../utils/apiRequest";
import { toast } from "react-toastify";
import { trackMetaEvent } from "../../utils/metaPixel";
import { pushGTMEvent } from "../../utils/GTMEventComponent";
import { BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE } from "../../utils/gtmEventConstants";

import { useDispatch, useSelector } from "react-redux";

const PricingCard = ({ title, price, description, onClick }) => (
  <div className="w-full bg-[#FDF4E8] text-white rounded-xl p-6 sm:p-8 flex flex-col space-y-4 sm:space-y-6 flex-1 shadow-lg border md:min-w-[350px]">
    <h3 className="text-orange-500 font-semibold text-lg sm:text-xl">
      {title}
    </h3>
    <div className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-black">
      Rs. {price}
    </div>
    <ul className="space-y-3">
      {description.map((desc, index) => (
        <li key={index} className="flex items-center">
          <div className="flex items-center justify-center rounded-full">
            <span className="w-3 h-3 rounded-full bg-[#8F4C38]"></span>
          </div>
          <span className="text-sm sm:text-base text-[#231B00] pl-3">
            {desc}
          </span>
        </li>
      ))}
    </ul>
    <button
      onClick={onClick}
      className="mt-auto w-full py-2 sm:py-3 px-4 bg-orange-500 text-white font-medium text-base sm:text-lg rounded-full hover:bg-orange-600 transition-all"
    >
      Select Package
    </button>
  </div>
);

const PricingTable = ({ pooja, id }) => {
  const headers = useAuthHeaders();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventType, setEventType] = useState("EVENT_END");
  const translations = useSelector((state) => state.language.translations);

  useEffect(() => {
    setEventType(pooja?.eventType);
  }, [pooja?.eventType]);

  const gotocart = async (pooja, plan) => {
    dispatch(emptyCart());

    await apiRequest({
      url: CART,
      method: "POST",
      data: {
        type: "PUJA",
        parentId: id,
        subType: "PUJA_PACKAGE",
        itemId: plan.id,
        quantity: 1,
      },
      headers,
    });

    trackMetaEvent("ADD_TO_CART", {
      content_name: plan.name,
      value: plan.amount,
      currency: "INR",
    });

    if (!!localStorage.getItem("userToken")) {
      navigate("/cart");
    }
  };

  const plans = pooja?.pujaPackageList;
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);

  return (
    <div className="flex justify-center pt-2 sm:pt-12 w-full sm:pb-12">
      <div className="w-full">
        <div className="text-center mb-4 sm:mb-10">
          <h2 className="text-[28px] md:text-3xl lg:text-4xl text-custom-maroon font-bold mb-2">
            {translations.waysToParticipate}
          </h2>
        </div>

        {/* {!isEventEnded ? (
          <div>
            
          </div>
        ) : (
          <div className="bg-red-100 text-red-700 px-4 py-4 sm:py-16 rounded-lg text-lg sm:text-2xl font-semibold shadow-md border border-red-300 text-center">
            Event Ended
          </div>
        )} */}

        <div>
          {eventType === "EVENT_END" && (
            <div className="bg-red-100 text-red-700 px-4 py-4 sm:py-16 rounded-lg text-lg sm:text-2xl font-semibold shadow-md border border-red-300 text-center">
              {translations.eventEnded}
            </div>
          )}

          {eventType === "INPROGRESS_EVENT" && (
            <div className="w-full max-w-7xl mx-auto">
              {/* Image selection row - visible on mobile, now with full width */}
              <div className="grid grid-cols-3 w-full mb-2 md:hidden gap-4">
                {plans.map((plan, index) => (
                  <div
                    key={`image-${plan.title}`}
                    className={`rounded-xl cursor-pointer overflow-hidden ${
                      selectedPlanIndex === index
                        ? "shadow-lg ring-4 ring-yellow-400 transform scale-105"
                        : ""
                    }`}
                    onClick={() => setSelectedPlanIndex(index)}
                  >
                    <img
                      src={
                        plan?.imageInfo?.url ||
                        "https://img.freepik.com/free-vector/indian-festival-happy-bhai-dooj-concept-design_1035-29876.jpg?t=st=1742123295~exp=1742126895~hmac=3003a07898d10dd2ace3bac11b53722f9c826c601c5c8e94e40029d15d28d960&w=740"
                      }
                      alt={plan?.imageInfo?.altText || "Puja Package Image"}
                      className="h-20 w-full object-cover"
                    />
                    <div className="relative bg-orange-500 text-white py-1 text-center">
                      <p>₹ {plan.amount}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Rest of the code remains the same */}
              <div className="md:hidden mt-5">
                {selectedPlanIndex !== null && (
                  <PricingCard
                    key={plans[selectedPlanIndex].title}
                    title={plans[selectedPlanIndex].name}
                    price={plans[selectedPlanIndex].amount}
                    description={plans[selectedPlanIndex].description}
                    onClick={() => {
                      pushGTMEvent(
                        BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE,
                        {
                          event_value: pooja?.mainSection?.name,
                          package_name: plans[selectedPlanIndex].name,
                        }
                      );
                      gotocart(pooja, plans[selectedPlanIndex]);
                    }}
                  />
                )}
              </div>

              <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-16 lg:gap-10 gap-4">
                {plans.map((plan) => (
                  <PricingCard
                    key={plan.title}
                    title={plan.name}
                    price={plan.amount}
                    description={plan.description}
                    onClick={() => {
                      pushGTMEvent(
                        BOOK_PUJA_PARTICIPATE_NOW_SELECT_PACKAGE_PARTICULAR_PAGE,
                        {
                          event_value: pooja?.mainSection?.name,
                          package_name: plan.name,
                        }
                      );
                      gotocart(pooja, plan);
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {eventType === "UPCOMING_EVENT" && (
            <div className="bg-red-100 text-red-700 px-4 py-4 sm:py-16 rounded-lg text-lg sm:text-2xl font-semibold shadow-md border border-red-300 text-center">
              {translations.eventUpcoming}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
