import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import ParticularChadawaHeader from "../components/ParticularPuja/Header";
import BenefitsOfChadawa from "../components/ParticularPuja/BenefitsOfChadawa";
import Testimonials from "../components/Common/Testimonials";
import Countdown from "../components/Common/Countdown";
import Details from "../components/ParticularPuja/Details";
import AboutChadawa from "../components/ParticularPuja/AboutChadawa";
import WaysToParticipate from "../components/ParticularPuja/waystopartcipate";
import SubscribeLandingpage from "../components/subscribe_landingpage";
import FAQSection from "../components/Common/FAQ";
import { PARTICULARPUJA, TESTIMONIAL } from "../rest/endpoints";

import ConditionalRenderer from "../devsathan_components/ConditionalRenderer";
import { useScroll } from "../devsathan_components/ScrollContext";
import { useAuthHeaders } from "../utils/getHeaders";
import { apiRequest } from "../utils/apiRequest";
import { toast } from "react-toastify";
import { pushGTMEvent } from "../utils/GTMEventComponent";
import { BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE } from "../utils/gtmEventConstants";
import { useSelector } from "react-redux";

const ParticularChadawa = () => {
  const { id } = useParams();
  const { waysToParticipateRef } = useScroll();

  const [isFixed, setIsFixed] = useState(true);
  const stickyRef = useRef(null);
  const { scrollToWaysToParticipate, isVisible } = useScroll();

  const headers = useAuthHeaders();
  const translations = useSelector((state) => state.language.translations);
  const buttonRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);
  const [pooja, setPooja] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [particularPujaId, setParticularPujaId] = useState(null);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const result = await apiRequest({
          url: `${PARTICULARPUJA}${id}`,
          method: "GET",
          headers,
        });

        // Update state with fetched data
        setPooja(result?.data);
        setParticularPujaId(result?.data?.id);
      } catch (error) {
        // Handle errors gracefully
        console.error(
          "Error fetching Puja data:",
          error.response?.message || error.message || "Network or unknown error"
        );
        toast.error("Failed to fetch Puja data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSlides();
  }, [id]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const result = await apiRequest({
          url: TESTIMONIAL,
          method: "GET",
          headers,
        });

        // Check if the testimonial data is an array of reviews
        if (result?.data?.reviews && Array.isArray(result.data.reviews)) {
          setTestimonials(result.data.reviews); // Update state with the reviews
        } else {
          console.error(
            "Testimonial data is missing or not in the expected format."
          );
          toast.error(
            "Testimonial data is not available or in an unexpected format."
          );
        }
      } catch (error) {
        // Handle errors and show appropriate message to the user
        console.error(
          "Error fetching testimonial data:",
          error.message || "Network or unknown error"
        );
        toast.error(
          "Failed to fetch testimonial data. Please try again later."
        );
      }
    };

    fetchTestimonials();
  }, []);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsVisible(!entry.isIntersecting);
  //     },
  //     {
  //       threshold: 0.1, // Adjust visibility threshold
  //       rootMargin: "0px",
  //     }
  //   );

  //   if (waysToParticipateRef.current) {
  //     observer.observe(waysToParticipateRef.current);
  //   }

  //   return () => {
  //     if (waysToParticipateRef.current) {
  //       observer.unobserve(waysToParticipateRef.current);
  //     }
  //   };
  // }, [waysToParticipateRef.current]);

  return (
    <div className="min-h-screen bg-[#ffece5]">
      {isLoading ? (
        <div className=" x flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
          <p className="text-center text-lg font-semibold text-custom-maroon">
            Loading...
          </p>
          <div className="animate-ping rounded-full h-32 w-32 border-t-2 border-b-2 border-[#FFB830]"></div>
        </div>
      ) : !pooja ? (
        <div>No data available</div>
      ) : (
        <>
          <ParticularChadawaHeader pooja={pooja} />
          <Details pooja={pooja} />
          <Countdown props={pooja} />
          <div
            ref={stickyRef}
            className={`${
              isFixed ? "fixed bottom-0 left-0 right-0" : "sticky top-14"
            } z-30 bg-[#FEEFDC] block sm:hidden px-10 pb-4 pt-3 w-full transition-all duration-300 ease-in-out`}
          >
            <div className="flex flex-row w-full md:w-auto md:mt-0 items-center justify-center sm:pl-6">
              <button className="hidden border-2 border-orange-500 text-orange-500 rounded-full font-semibold hover:bg-orange-50 px-4 py-2">
                {translations.shareButton}
              </button>
              {isVisible && (
                <button
                  onClick={() => {
                    scrollToWaysToParticipate();
                    pushGTMEvent(BOOK_PUJA_PARTICIPATE_NOW_PARTICULAR_PAGE, {
                      event_value: pooja?.mainSection?.name,
                    });
                  }}
                  ref={buttonRef}
                  // style={{ display: isVisible ? "block" : "none" }}
                  // className={`transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                  className="bg-orange-500 w-full text-white px-6 py-2 rounded-[12px] font-semibold hover:bg-orange-600 text-[3.5vw] transition-all duration-300 ease-in-out "
                >
                  {translations.participateNow}
                </button>
              )}
            </div>
          </div>

          <div
            ref={waysToParticipateRef}
            className="bg-[#FEEFDC] px-8 md:px-14"
          >
            <WaysToParticipate pooja={pooja} id={particularPujaId} />
          </div>
          <AboutChadawa pooja={pooja} />
          <BenefitsOfChadawa pooja={pooja} />
          <ConditionalRenderer componentKey="SubscribeLandingpage">
            <SubscribeLandingpage />
          </ConditionalRenderer>
          <Testimonials testimonials={testimonials} />
          <div className="pb-10">
            <FAQSection faqs={pooja.faqSection} />
          </div>
        </>
      )}
    </div>
  );
};

export default ParticularChadawa;
