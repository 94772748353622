import React, { useState, useEffect } from "react";
import PujaEventDisplay from "../components/puja_slider/PujaEventDisplay";
import DivineConnection from "../components/divineconnetion_lh";
import HowDevsthanworks from "../components/howDevsthanhworks";
import FAQSection from "../components/Common/FAQ";
import Testimonials from "../components/Common/Testimonials";
import SubscribeLandingpage from "../components/subscribe_landingpage";
import { useSwipeable } from "react-swipeable";
import { apiRequest } from "../utils/apiRequest";
import { toast } from "react-toastify";
import ImageWithText from "../devsathan_components/Imagewithtext/imagewithtext";
import { useNavigate } from "react-router-dom";
import {
  ALLPUJA,
  HOMEPAGEBANNER,
  HOMEPAGEFAQ,
  TESTIMONIAL,
} from "../rest/endpoints";
import { useDispatch } from "react-redux";
// import componentsConfig from "../devsathan_components/componentsConfig";
import ConditionalRenderer from "../devsathan_components/ConditionalRenderer";
import { ALLOFFERINGS } from "../rest/endpoints";
import { useRef } from "react";
import ImageSection from "../components/Cards/offeringCardComponents/ImageSection";
import DateSection from "../components/Cards/offeringCardComponents/DateSection";
import TitleAndLocation from "../components/Cards/offeringCardComponents/TitleAndLocation";
import OfferSection from "../components/Cards/offeringCardComponents/OfferSection";

import { useSelector } from "react-redux";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import ExploreMoreCard from "../devsathan_components/ExploreMore/exploremore";

import { useAuthHeaders } from "../utils/getHeaders";
import { pushGTMEvent } from "../utils/GTMEventComponent";
import { BOOK_PUJA } from "../utils/gtmEventConstants";
import { BOOK_CHADAWA } from "../utils/gtmEventConstants";
import { SLIDER_CHADAWA } from "../utils/gtmEventConstants";

import "react-loading-skeleton/dist/skeleton.css";
const HeroSection = () => {
  const headers = useAuthHeaders();
  const [offerings, setOfferings] = useState([]);
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.language.translations);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const navigate = useNavigate();
  const [slides, setSlides] = useState([
    {
      title: "",
      description: "Durga Ji Image",
      imageInfo: {
        imageText: null,
        url: "",
        altText: "Banner Image",
      },
    },
  ]);
  const [pujaEvents, setPujaEvents] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeButton, setActiveButton] = useState("Puja"); // Default active button is "Puja"
  const [testimonials, setTestimonials] = useState([]);
  // const [email, setEmail] = useState("");
  const [faqs, setFaqs] = useState([]);

  const scrollContainerRef = useRef(null);
  // const [loadingSlides, setLoadingSlides] = useState(true);

  const handleExploreMore = () => {
    navigate("/chadawa");
  };

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const { current: container } = scrollContainerRef;
      const scrollAmount = container.offsetWidth; // Width of the container

      if (direction === "left") {
        container.scrollBy({
          left: -scrollAmount, // Scroll left
          behavior: "smooth",
        });
      } else if (direction === "right") {
        container.scrollBy({
          left: scrollAmount, // Scroll right
          behavior: "smooth",
        });
      }
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle subscription logic here
  //   console.log("Subscribing email:", email);
  // };
  const handleSwipeLeft = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };

  const handleSwipeRight = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(slides.length - 1);
    }
  };

  // Configure swipe handlers
  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    trackMouse: true,
  });

  // Auto-slide functionality that continues regardless of user interaction
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((current) =>
        current === slides.length - 1 ? 0 : current + 1
      );
    }, 4000); // Change slide every 2 seconds

    return () => clearInterval(intervalId);
  }, [slides.length]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const result = await apiRequest({
          url: HOMEPAGEBANNER,
          method: "GET",
          headers,
        });

        if (Array.isArray(result?.data?.banners)) {
          setSlides(result.data.banners); // Set state with the fetched banners data
        } else {
          console.error(
            "Banners data is missing or not in the expected format."
          );
          toast.error("Banners data is unavailable.");
        }
      } catch (error) {
        const errorMessage =
          error.response?.message || error.message || "Something went wrong";
        setError(errorMessage);
        console.error("Error fetching homepage banners:", errorMessage);
        toast.error(errorMessage);
      } finally {
        // setLoading(false); // Optional loading state logic
      }
    };

    const fetchData = async () => {
      try {
        const result = await apiRequest({
          url: `${ALLOFFERINGS}0`,
          method: "GET",
          headers,
        });

        if (Array.isArray(result?.data?.offeringList)) {
          setOfferings(result.data.offeringList); // Set state with the fetched offerings data
        } else {
          console.error(
            "Offerings data is missing or not in the expected format."
          );
          toast.error("Offerings data is unavailable.");
        }
      } catch (error) {
        console.error(
          "Error fetching offerings:",
          error.response?.message || error.message || "Network or unknown error"
        );
        toast.error(
          error.response?.message ||
            error.message ||
            "Failed to fetch offerings."
        );
      }
    };
    fetchSlides();
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPooja = async () => {
      try {
        const result = await apiRequest({
          url: ALLPUJA,
          method: "GET",
          headers,
        });

        if (Array.isArray(result?.data?.pujaList)) {
          setPujaEvents(result.data.pujaList); // Set state with the fetched puja data
        } else {
          console.error("Puja data is missing or not in the expected format.");
          toast.error("Puja data is unavailable.");
        }
      } catch (error) {
        console.error(
          "Error fetching Puja data:",
          error.message || "Network or unknown error"
        );
        toast.error(error.message || "Failed to fetch Puja data.");
      }
    };
    fetchPooja();
  }, []);

  useEffect(() => {
    const fetchfaq = async () => {
      try {
        const result = await apiRequest({
          url: HOMEPAGEFAQ,
          method: "GET",
          headers,
        });

        if (Array.isArray(result?.data?.faqs)) {
          setFaqs(result.data.faqs); // Set state with the fetched FAQ data
        } else {
          console.error("FAQ data is missing or not in the expected format.");
          toast.error("FAQ data is unavailable.");
        }
      } catch (error) {
        console.error(
          "Error fetching FAQ data:",
          error.message || "Network or unknown error"
        );
        toast.error(error.message || "Failed to fetch FAQ data.");
      }
    };

    fetchfaq();
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const result = await apiRequest({
          url: TESTIMONIAL,
          method: "GET",
          headers,
        });

        if (Array.isArray(result?.data?.reviews)) {
          setTestimonials(result.data.reviews); // Set state with the fetched testimonial data
        } else {
          console.error(
            "Testimonial data is missing or not in the expected format."
          );
          toast.error("Testimonial data is unavailable.");
        }
      } catch (error) {
        console.error(
          "Error fetching testimonial data:",
          error.message || "Network or unknown error"
        );
        toast.error(error.message || "Failed to fetch testimonial data.");
      }
    };

    fetchTestimonials();
  }, []);

  const handleManualScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const firstCardWidth = container.firstChild?.offsetWidth || 0;

    if (
      !window._gtmSwipeFired &&
      container.scrollLeft > firstCardWidth / 2 &&
      window._userIsSwiping
    ) {
      pushGTMEvent(SLIDER_CHADAWA);
      window._gtmSwipeFired = true;
    }
  };

  const markUserAsSwiping = () => {
    window._userIsSwiping = true;
  };

  const gotoPage = (page) => {
    if (page === "puja-listing") {
      pushGTMEvent(BOOK_PUJA);
    } else if (page === "chadawa") {
      pushGTMEvent(BOOK_CHADAWA);
    }
    navigate(`/${page}`);
  };

  return (
    <div className=" bg-[#FFECE5] min-h-screen mt-16 sm:px-8 px-2">
      <div className=" max-w-full mx-auto px-6 sm:px-8 py-8 md:pt-10 pb-6">
        <div className="grid custom-md:grid-cols-2 gap-8 sm:gap-6 items-center">
          {/* Left Content */}
          <div className="space-y-4 order-2 custom-md:order-1 text-center custom-md:text-left px-0 sm:px-10">
            {(() => {
              if (!translations.heading1) return null;
              const parts = translations.heading1.split(",");
              const firstLine = parts[0] + ",";
              const secondLine = parts.slice(1).join(",");
              return (
                <h1
                  className="text-balance text-[clamp(1.25rem,5vw,2.25rem)] font-bold leading-snug text-custom-maroon xs:-mx-6"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    lineHeight: "1.2",
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  <span className="block">{firstLine}</span>
                  <span className="block">{secondLine}</span>
                </h1>
              );

            })()}

            {/* <p className="text-sm xs:text-md md:text-lg lg:text-base text-custom-brown mx-2 xs:mx-0">
              {translations.subHeading1}
            </p> */}
            <div className="flex flex-row gap-3 justify-center custom-md:justify-start mx-auto custom-md:mx-0">
              <button
                className="px-4 py-2 sm:px-5 sm:py-3 bg-[#FF5C35] text-white rounded-full hover:bg-[#FF4B1F] transition-colors duration-200 whitespace-nowrap"
                onClick={() => gotoPage("puja-listing")}
              >
                {translations.bookPuja}
              </button>
              <button
                className="px-4 py-2 sm:px-5 sm:py-3 text-[#FF5C35] border border-[#FF5C35] rounded-full hover:bg-[#FF5C35] hover:text-white transition-colors duration-200 whitespace-nowrap"
                onClick={() => gotoPage("Chadawa")}
              >
                {translations.exploreChadawa}
              </button>
            </div>
          </div>

          {/* Right Carousel */}
          <div
            {...handlers}
            className="relative rounded-2xl overflow-hidden shadow-xl order-1 custom-md:order-2 w-full max-w-[800px] mx-auto"
          >
            {/* Image and Overlay */}
            <div className="relative w-full aspect-w-16 aspect-h-9 sm:aspect-w-6 sm:aspect-h-3">
              <ImageWithText
                // Fallback to home if undefined
                slides={slides[currentSlide]}
              />
            </div>

            {/* Navigation Dots */}
            <div className="absolute bottom-4 right-4 flex space-x-2">
              {slides.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-2 h-2 rounded-full transition-all duration-200 
          ${
            currentSlide === index
              ? "w-6 bg-white"
              : "bg-white/50 hover:bg-white/75"
          }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="max-w-screen-lg mx-auto mt-7">
          <h1 className="text-[clamp(1.25rem,5vw,2.25rem)] text-custom-maroon font-bold text-center whitespace-nowrap overflow-hidden text-ellipsis">
            {translations.heading2}
          </h1>

          <p className="text-center text-sm md:text-lg lg:text-base text-custom-brown mt-2 mb-2">
            {translations.subHeading2}
          </p>
          <div className="bg-[#FFDBD1] flex justify-evenly gap-4 max-w-lg mx-auto rounded-full shadow-md p-2">
            {/* Puja Button */}
            <button
              onClick={() => setActiveButton("Puja")}
              className={`flex-1 font-medium px-6 py-2 rounded-full shadow-md transition-colors duration-200 
            ${
              activeButton === "Puja"
                ? "bg-[#3A1700] text-white"
                : "bg-transparent border-2 border-[#3A1700] text-[] hover:bg-[#3A1700] hover:text-white"
            }`}
            >
              {translations.puja}
            </button>
            {/* Chadawa Button */}
            <button
              onClick={() => setActiveButton("Chadawa")}
              className={`flex-1 font-medium px-6 py-2 rounded-full shadow-md transition-colors duration-200 
            ${
              activeButton === "Chadawa"
                ? "bg-[#3A1700] text-white"
                : "bg-transparent border-2 border-[#3A1700] text-[#3A1700] hover:bg-[#3A1700] hover:text-white"
            }`}
            >
              {translations.chadawa}
            </button>
          </div>
        </div>

        {/* Puja Event Slider */}

        {activeButton === "Puja" && (
          <div className=" overflow-x-auto whitespace-nowrap">
            <PujaEventDisplay events={pujaEvents} viewType="slider" />
          </div>
        )}

        {activeButton === "Chadawa" && (
          <div className="relative overflow-hidden pt-8">
            {/* Left Arrow */}
            {offerings.length > 1 && (
              <button
                onClick={() => scroll("left")}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white text-custom-maroon p-2 rounded-full z-10 sm:block hidden"
              >
                <ArrowBackIos className="pl-2" /> {/* Left Arrow Icon */}
              </button>
            )}

            {/* Scrollable Container */}
            <div
              className={`pb-4 flex flex-row scroll-smooth sm:mx-20 sm:max-w-[88vw]
        ${
          offerings.length > 1
            ? "overflow-x-scroll [&::-webkit-scrollbar]:w-0 [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            : "overflow-hidden"
        }
      `}
              ref={scrollContainerRef}
              onScroll={handleManualScroll}
              onTouchStart={markUserAsSwiping} // Mobile swiping
              onMouseDown={markUserAsSwiping} // Desktop drag/swipe
              onWheel={markUserAsSwiping}
            >
              <div className="w-full sm:w-[420px] max-w-lg flex flex-row space-x-7">
                {/* Offerings */}
                {offerings.map((offering) => (
                  <div
                    key={offering.id}
                    className="min-w-[84.5vw] sm:min-w-[420px] mx-auto border bg-[#fdf4e8] rounded-[24px] overflow-hidden relative flex flex-col shadow-xl"
                  >
                    <ImageSection offering={offering} />
                    <div className="flex flex-col flex-grow px-4 pb-1 pt-2 bg-[#fdf4e8]">
                      <div className="flex-grow">
                        <DateSection offering={offering.mainSection} />
                        <TitleAndLocation offering={offering.mainSection} />
                      </div>
                      <OfferSection offering={offering} />
                    </div>
                  </div>
                ))}

                {/* ✅ Now the ExploreMoreCard is AFTER all offerings */}
                {offerings.length > 1 && (
                  <div className="snap-start flex-shrink-0 w-72 h-full p-2 pt-16 pb-9">
                    <ExploreMoreCard onClick={handleExploreMore} />
                  </div>
                )}
              </div>
            </div>

            {/* Right Arrow */}
            {offerings.length > 1 && (
              <button
                onClick={() => scroll("right")}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white text-custom-maroon p-2 rounded-full z-10 sm:block hidden"
              >
                <ArrowForwardIos className="pr-1 pl-1" />{" "}
                {/* Right Arrow Icon */}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="bg-[#F8BB71] w-full mt-12">
        <DivineConnection />
      </div>
      <div className="md:px-14 -mt-1 sm:-mt-6 -mb-12">
        <Testimonials testimonials={testimonials} />
      </div>
      {/* {componentsConfig.SubscribeLandingpage && <SubscribeLandingpage />} */}
      <ConditionalRenderer componentKey="SubscribeLandingpage">
        <SubscribeLandingpage />
      </ConditionalRenderer>

      <div className="md:px-14 pt-4 pb-8">
        <HowDevsthanworks />
        <ConditionalRenderer componentKey="SubscribeLandingpage">
          <FAQSection faqs={faqs} />
        </ConditionalRenderer>
      </div>
    </div>
  );
};

export default HeroSection;
